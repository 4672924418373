<template>
	<StyleCollapseControl
		:element="element"
		:property="getProperty(PROPERTY_BORDER_NULL, state)"
		collapse-title="Stroke"
	>
		<div class="form-group">
			<div class="form-group__item form-grid form-grid--2">
				<StylePropertyControl
					:element="element"
					:property="getProperty(PROPERTY_BORDER_WIDTH, state)"
					label="Width"
				/>
				<StyleColorControl
					:element="element"
					:property="getProperty(PROPERTY_BORDER_COLOR, state)"
					label="Color"
				/>
			</div>
		</div>
	</StyleCollapseControl>
</template>

<script>
import StyleCollapseControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleCollapseControl.vue';
import StyleColorControl from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleColorControl.vue';
import StylePropertyControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StylePropertyControl.vue';
import { getProperty } from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/utils/getProperty';
import {
	PROPERTY_BORDER_COLOR,
	PROPERTY_BORDER_WIDTH,
	PROPERTY_BORDER_NULL,
} from '@/constants/globalStyles';

export default {
	getProperty,
	components: {
		StylePropertyControl,
		StyleCollapseControl,
		StyleColorControl,
	},
	props: {
		element: {
			type: String,
			required: true,
		},
		state: {
			type: String,
			default: null,
			validator(value) {
				return ['hover'].includes(value);
			},
		},
	},
	setup() {
		return {
			PROPERTY_BORDER_COLOR,
			PROPERTY_BORDER_WIDTH,
			PROPERTY_BORDER_NULL,
			getProperty,
		};
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/_forms.scss';
</style>
