<template>
	<ZyroCollapse
		size="x-large"
		has-separator
	>
		<template #trigger>
			<h3 class="z-body-small z-body-small--strong">
				{{ capitalizeFirstLetter(state) }}
			</h3>
		</template>
		<div>
			<BackgroundControl
				:element="element"
				:state="state"
			/>
			<BorderControl
				:element="element"
				:state="state"
			/>
			<ShadowControl
				:element="element"
				:state="state"
			/>
			<div class="form-group">
				<h3
					class="form-group__header form-group__header--padding z-subheading z-subheading--spaced"
				>
					Button text
				</h3>
				<div class="form-group__item form-grid">
					<StyleColorControl
						:element="element"
						property="color"
						:state="state"
						label="Color"
					/>
				</div>
			</div>
		</div>
	</ZyroCollapse>
</template>

<script>
import StyleColorControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleColorControl.vue';
import BackgroundControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/sections/BackgroundControl.vue';
import BorderControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/sections/BorderControl.vue';
import ShadowControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/sections/ShadowControl.vue';
import {
	PROPERTY_COLOR,
	STATE_HOVER,
} from '@/constants/globalStyles';
import { capitalizeFirstLetter } from '@/utils/modifyString';

export default {
	components: {
		ShadowControl,
		BorderControl,
		BackgroundControl,
		StyleColorControl,
	},
	props: {
		element: {
			type: String,
			required: true,
		},
		state: {
			type: String,
			required: true,
			validator(value) {
				return [STATE_HOVER].includes(value);
			},
		},
	},
	setup() {
		return {
			PROPERTY_COLOR,
			STATE_HOVER,
			capitalizeFirstLetter,
		};
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/_forms.scss';
</style>
